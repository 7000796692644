import { useEffect, useState } from 'react'

import { useLead } from '@itau-loans-www/shopping/src/hooks'
import analytics from '@mobi/libraries/analytics'
import { formatters, goTo } from 'utils'

import services from './services'

const useDesiredLoanCustomize = () => {
  const [loading, setLoading] = useState(false)
  const { changeLeadData, leadData } = useLead()

  const { number } = formatters

  useEffect(() => {
    services.getDesiredLoanData().then((data) => {
      if (leadData.variant === 'A' && leadData.vwoVariant !== 'A') {
        changeLeadData({ previousStep: '/proposta/telefone' })
        return
      }
      changeLeadData({ previousStep: data?.previous_step || 'basic-data' })
    })
  }, [])

  const onSubmit = async (payload) => {
    setLoading(true)

    const { customize } = payload

    const requestBody = {
      desired_loan_value: number.currencyToFloat(customize)
    }

    analytics.track('buttonClick', {
      currentPage: 'valor-emprestimo',
      detail: 'click-step-valor-emprestimo',
      customLayer: {
        value: customize
      }
    })

    return services
      .putDesiredLoanData(requestBody)
      .then(({ next_step }) => goTo(next_step))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    changeLeadData({
      previousStep: 'basic-data'
    })
  }, [])

  useEffect(() => {
    analytics.track('pageLoad', { currentPage: 'formulario-valor-emprestimo' })
  }, [])

  return { onSubmit, loading }
}

export default useDesiredLoanCustomize
